import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../store/courses"
import LayoutCourse from "../../../../layouts/course"

import AudioPlayer from "../../../../components/audio-player"
import Box from "../../../../components/box"
import Paragraph from "../../../../components/paragraph"
import CourseFooterNext from "../../../../components/course-footer-next"
import FloatContainer from "../../../../components/float-container"
import Heading from "../../../../components/heading"
import Image from "../../../../components/image"
import NoBreak from "../../../../components/no-break"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query AndenkenSuedfrankreichQuery {
      portrait: file(relativePath: { eq: "kurse/hiemer-portrait.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/lesen-und-einpraegen" />
      }
      navigation={navigation}
    >
      <Seo title="Friedrich Hölderlin - ein Wanderer" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 1
            </Heading>
            <Heading as="h2" level={2}>
              Friedrich Hölderlin - ein Wanderer
            </Heading>
          </Stack>
          <AudioPlayer src={`kurse/reiseerinnerung/einleitung`} />
          <Box>
            <FloatContainer align="right">
              <Image
                image={data.portrait.childImageSharp.gatsbyImageData}
                alt="Hölderlin im Alter von 22 Jahren. Pastell von Franz Carl Hiemer."
                caption="Hölderlin im Alter von 22 Jahren. Pastell von Franz Carl Hiemer."
                attribution="DLA Marbach"
              />
            </FloatContainer>
            <Paragraph dropcap={true} mb={[6, 12]}>
              Der Dichter Friedrich Hölderlin war ein rastloser Wanderer. Reisen
              aus purem Vergnügen waren bei ihm selten. Sein großes
              Sehnsuchtsland Griechenland hat er nie mit eigenen Augen gesehen.
              Doch brachte ihn sein von zahlreichen Auf- und Abbrüchen
              gezeichneter Lebensweg immerhin bis nach Frankreich. Geboren 1770
              in Lauffen am Neckar studierte er auf Wunsch seiner Mutter von
              1788-1793 Theologie am Evangelischen Stift in Tübingen. Es war die
              Zeit der Französischen Revolution, die Zeit der politischen
              Umbrüche und die Zeit der großen Klassiker Schiller und{" "}
              <NoBreak>Goethe</NoBreak>.
            </Paragraph>
            <Paragraph mb={[6, 12]}>
              Dass er Schriftsteller werden wollte, wusste Hölderlin schon früh.
              Von diesem Wunsch angetrieben, flüchtete er vor der für ihn
              vorgesehenen Pfarrers-Laufbahn, indem er verschiedene Stellen als
              Hauslehrer in wohlhabenden Familien annahm. So kam er nach
              Waltershausen an der Saale und nach Frankfurt, aber auch nach
              Hauptwil in die Schweiz und bis ins französische Bordeaux -
              Strecken, die er größtenteils zu Fuß und teils unter gefährlichen
              Bedingungen zurückgelegt hat. Vieles Gesehene und Erlebte ist
              dabei auch in seine Gedichte eingegangen. Eines davon trägt den
              Titel ›Andenken‹.
            </Paragraph>
            <Paragraph>
              Im 1. Kapitel werden wir das Gedicht auf darin vorkommende
              Landschaftsbeschreibungen und Ortsbezüge analysieren sowie den
              biografischen und zeitgeschichtlichen Hintergründen nachspüren.
              Wir werden versuchen, die Stimmungen der Verse einzufangen – und
              zuletzt ein Work & Travel-Programm für Hölderlin entwerfen.
            </Paragraph>
          </Box>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
